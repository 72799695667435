const initialReservation = {
  date: '',
  time: '',
  date_id: '',
  time_id: '',
  transport: '',
  ticket_type: '',
  ticket_quantity: '',
  passengers: [],
};

const mutations = {
  SET_DATA_RESERVATION(state, payload) {
    Object.entries(payload).forEach(([key, value]) => {
      if (key in state.reservation) {
        state.reservation[key] = value;
      }
    });
  },
  INIT_STATE_RESERVATION(state) {
    state.reservation = initialReservation;
  },
  UPDATE_PASSENGERS(state, newPassengers) {
    state.reservation.passengers = newPassengers;
  },
};

export default mutations;
