// Genera un ID único para cada pasajero
function generateUniqueId() {
  return `_${Math.random().toString(36).substr(2, 9)}`;
}

const actions = {
  // Actualiza la cantidad de boletos y ajusta la lista de pasajeros
  updateTicketQuantity({ commit }, quantity) {
    // Crea una nueva lista de pasajeros con la cantidad indicada
    const newPassengers = Array.from({ length: quantity }, () => ({
      id: generateUniqueId(), // Genera un ID único para cada nuevo pasajero
      name: '',
      lastName: '',
      email: '',
      phone: '',
      type: 'adulto',
      country: '',
      state: '',
    }));

    // Actualiza la lista de pasajeros y la cantidad de boletos en el estado
    commit('UPDATE_PASSENGERS', newPassengers);
    commit('SET_DATA_RESERVATION', { ticket_quantity: quantity });
  },
};

export default actions;
