<template>
  <div class="seat-selection">
    <SeatsBus></SeatsBus>
  </div>
</template>

<script>
import SeatsBus from './seatsBus.vue';

export default {
  components: {
    SeatsBus,
  },
  data() {
    return {
      selectedSeats: [],
    };
  },
  methods: {},
};
</script>

<style scoped>
.seat-selection {
  padding: 20px 0;
}
</style>
