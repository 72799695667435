<template>
  <div class="ticket-tipe-page py-6">
<!--     {{ reservation }} -->
    <div class="container box">
      <b-steps
        v-model="activeStep"
        :animated="isAnimated"
        :rounded="isRounded"
        :has-navigation="hasNavigation"
        :icon-prev="prevIcon"
        :icon-next="nextIcon"
        :label-position="labelPosition"
        :mobile-mode="mobileMode"
      >
        <!-- Paso 1: Selección del tipo de boleto y cantidad de pasajeros -->
        <b-step-item step="1" label="Tipo de Boleto" :clickable="isStepsClickable" class="box">
          <h1 class="title has-text-centered mt-6">Selección del Boleto</h1>
          <div class="columns is-multiline is-centered">
            <div class="column is-6 mb-6">
              <b-field label="Tipo de Boleto">
                <b-radio v-model="reservation.ticket_type" @input="onChange('ticket_type', $event)" native-value="oro">Oro</b-radio>
                <b-radio v-model="reservation.ticket_type" @input="onChange('ticket_type', $event)" native-value="plata">Plata</b-radio>
              </b-field>
              <b-field label="Cantidad de Pasajeros">
                <b-input v-model="reservation.ticket_quantity" type="number" min="1" @input="updatePassengerCount($event)"></b-input>
              </b-field>
              <p><strong>Fecha seleccionada:</strong> {{ selectedDate }}</p>
              <p><strong>Hora seleccionada:</strong> {{ selectedTime }}</p>
            </div>
          </div>
        </b-step-item>

        <!-- Paso 2: Información de los pasajeros -->
        <b-step-item step="2" label="Datos de Pasajeros" :clickable="isStepsClickable">
          <h1 class="title has-text-centered mt-6">Datos de los Pasajeros</h1>
          <div class="columns is-multiline is-centered">
            <div v-for="(passenger, index) in passengers" :key="index" class="column is-12 box">
              <div class="columns is-multiline">
                <div class="column is-12">
                  <h3 class="title is-5">Pasajero #{{ index + 1 }}</h3>
                </div>
                <b-field label="Nombre" class="column is-3">
                  <b-input v-model="passenger.name"></b-input>
                </b-field>
                <b-field label="Apellidos" class="column is-3">
                  <b-input v-model="passenger.lastName"></b-input>
                </b-field>
                <b-field label="Correo" class="column is-3">
                  <b-input v-model="passenger.email" type="email"></b-input>
                </b-field>
                <b-field label="Teléfono" class="column is-3">
                  <b-input v-model="passenger.phone" type="tel"></b-input>
                </b-field>
                <b-field label="Tipo de Pasajero" class="column is-3">
                  <b-select v-model="passenger.type" expanded>
                    <option value="adulto">Adulto</option>
                    <option value="nino">Niño</option>
                    <option value="tercera_edad">3ra Edad</option>
                  </b-select>
                </b-field>
                <b-field label="País" class="column is-3">
                  <b-input v-model="passenger.country"></b-input>
                </b-field>
                <b-field label="Estado" class="column is-3">
                  <b-input v-model="passenger.state"></b-input>
                </b-field>
              </div>
            </div>
          </div>
        </b-step-item>

        <!-- Paso 3: Selección de Asientos -->
        <b-step-item step="3" label="Seleccionar Asientos" :clickable="isStepsClickable">
          <h1 class="title has-text-centered mt-6">Selección de Asientos</h1>
          <Seats />
        </b-step-item>

        <!-- Paso 4: Finalizar Orden -->
        <b-step-item step="4" label="Finalizar Orden" :clickable="isStepsClickable">
          <h1 class="title has-text-centered mt-6">Finalizar Orden</h1>
          <!-- Aquí se integrará el componente de finalización de pago, como PayPal -->
          <PaymentConfirmation :reservation="reservation" />
        </b-step-item>

        <!-- Botones de navegación personalizados -->
        <template v-if="customNavigation" #navigation="{ previous, next }">
          <div class="buttons is-centered mt-5">
            <b-button outlined type="is-info" icon-pack="fas" icon-left="backward" :disabled="previous.disabled" @click.prevent="previous.action">
              Anterior
            </b-button>
            <b-button outlined type="is-info" icon-pack="fas" icon-right="forward" :disabled="next.disabled" @click.prevent="next.action">
              Siguiente
            </b-button>
          </div>
        </template>
      </b-steps>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

import Seats from './seatSelection.vue';
import PaymentConfirmation from './paymentConfirmation.vue';

export default {
  components: {
    Seats,
    PaymentConfirmation,
  },
  props: {
    reservationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      activeStep: 0,
      ticketType: 'oro',
      passengerCount: 1,
      selectedDate: '2024-08-20', // Ejemplo de fecha seleccionada
      selectedTime: '11:00 AM', // Ejemplo de hora seleccionada

      // Opciones de configuración del componente de pasos
      isAnimated: true,
      isRounded: true,
      isStepsClickable: false,
      hasNavigation: false,
      customNavigation: true,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
    };
  },
  computed: {
    ...mapState({
      reservation: (state) => state.reservations.reservation,
      passengers: (state) => state.reservations.reservation.passengers,
    }),
  },
  mounted() {
    window.scrollTo(0, 0);
    this.initReservation();
  },
  watch: {
    passengers: {
      handler(newPassengers) {
        this.updatePassengers(newPassengers);
      },
      deep: true, // Esto asegura que se observen los cambios profundos en el array y sus objetos
    },
  },
  methods: {
    ...mapActions({
      updateTicketQuantity: 'reservations/updateTicketQuantity',
      updatePassengerDetails: 'reservations/updatePassengerDetails',
    }),
    ...mapMutations({
      setDataReservation: 'reservations/SET_DATA_RESERVATION',
      updatePassengers: 'reservations/UPDATE_PASSENGERS',
    }),
    initReservation() {
      const reservation = {
        date: '',
        time: '',
        date_id: '',
        time_id: '',
        transport: '',
        ticket_quantity: 1,
        ticket_type: 'oro',
      };

      this.setDataReservation(reservation);
      this.updatePassengerCount(reservation.ticket_quantity);
    },
    onChange(key, value) {
      const payload = { [key]: value };
      this.setDataReservation(payload);
    },
    updatePassengerCount(value) {
      this.updateTicketQuantity(value);
    },
    nextStep() {
      if (this.activeStep < 4) {
        this.activeStep++;
      }
    },
    prevStep() {
      if (this.activeStep > 1) {
        this.activeStep--;
      }
    },
  },
};
</script>

<style lang="scss">
.ticket-type-selection {
  .steps {
    margin-bottom: 2rem;
  }
  .step-content {
    background: #f5f5f5;
    padding: 20px;
    border-radius: 5px;
  }
}
</style>
