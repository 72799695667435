<template>
  <div class="footer">
    <div class="container">
      <div class="columns is-multiline">
        <div class="column is-4">
          <div class="aliados">
            <div class="columns is-multiline">
              <div class="column is-12">
                <p class="title has-text-secondary">ALIADOS ESTRATEGICOS:</p>
              </div>
            </div>
            <div class="logos">
              <div class="columns is-multiline">
                <div class="column is-6">
                  <img class="sauza" src="@/assets/images/logo-sauza.png" alt="Logo" />
                </div>
                <div class="column is-6">
                  <img class="coca-cola" src="@/assets/images/logo-cocacola.png" alt="Logo" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-4">
          <div class="social-networks">
            <div class="columns is-multiline">
              <div class="column">
                <a href="https://www.facebook.com/tequila.xpress/" target="_blank" rel="noopener noreferrer">
                  <img src="@/assets/images/facebook.png" alt="Logo" />
                </a>
              </div>
              <div class="column">
                <a href="https://www.instagram.com/tequilaexpress/" target="_blank" rel="noopener noreferrer">
                  <img src="@/assets/images/instagram.png" alt="Logo" />
                </a>
              </div>
              <div class="column">
                <a href="/" target="_blank" rel="noopener noreferrer">
                  <img src="@/assets/images/tiktok.png" alt="Logo" />
                </a>
              </div>
              <div class="column">
                <a
                  href="https://www.tripadvisor.com.mx/Attraction_Review-g150798-d558550-Reviews-Tequila_Express-Guadalajara_Guadalajara_Metropolitan_Area.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="@/assets/images/tripadvisor.png" alt="Logo" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-4">
          <div class="box has-background-black">
            <a
              class="title has-text-white"
              href="https://wa.me/523335592398?text=¡Hola!%20Necesito%20información%20sobre%20los%20tours%20a%20Tequila,%20Jalisco."
              target="_blank"
              >INFORMES Y RESERVACIONES: <i class="fab fa-whatsapp"></i
            ></a>
          </div>
        </div>
      </div>
      <div class="columns is-multiline is-centered is-vcentered">
        <div class="column is-2">
          <img class="logo-footer" src="@/assets/images/logo-tequila2.png" alt="Logo" />
        </div>
        <div class="column is-5">
          <a class="title-links has-text-secondary" target="_blank" href="https://maps.app.goo.gl/LiBQjM8bvsYZD2np7"
            >Av. Vallarta # 4095, Fracc. Camino Real Zapopan, Jalisco, México</a
          >
          <br />
          <a class="title-links has-text-secondary" href="mailto:azulexpress@camaradecomerciogdl.mx">azulexpress@camaradecomerciogdl.mx</a>
          <a
            class="title-links has-text-secondary"
            href="https://wa.me/523335592398?text=¡Hola!%20Necesito%20información%20sobre%20los%20tours%20a%20Tequila,%20Jalisco."
            target="_blank"
          >
            WhatsApp: 33-3559-2398
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>