<template>
  <div class="columns is-centered is-vcentered is-multiline">
    <div class="column is-half">
      <div id="paypal-button-container"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'paymentConfirmation',
  props: {
    reservation: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    // Cargar el script de PayPal dinámicamente
    const script = document.createElement('script');
    script.src = 'https://www.paypal.com/sdk/js?client-id=AcLyVPEn4kdI8AI1U3OhQoNgzVtRkPXGL1GEmGi5ZlD6edxHVIE7O98_iZ3XuXHjWEwvqKTSWbX2hbbj';
    script.onload = () => {
      this.renderPayPalButton();
    };
    document.body.appendChild(script);
  },
  methods: {
    renderPayPalButton() {
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            // Usar los datos de reserva para crear la orden
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    currency_code: 'USD',
                    value: this.calculateAmount(), // Usa una función para calcular el monto
                  },
                  description: `Reserva para ${this.reservation.ticket_quantity} boletos`,
                },
              ],
            });
          },
          onApprove: (data, actions) => {
            // Maneja el éxito del pago aquí
            console.log(actions);
            return actions.order.capture().then(function (details) {
              alert('Transaction completed by ' + details.payer.name.given_name);
            });
          },
          onError: (err) => {
            console.error('PayPal Checkout Error:', err);
          },
        })
        .render('#paypal-button-container');
    },
    calculateAmount() {
      // Implementa la lógica para calcular el monto total basado en la reserva
      const pricePerTicket = 20.0; // Ejemplo, cambia esto según tu lógica
      return (pricePerTicket * this.reservation.ticket_quantity).toFixed(2);
    },
  },
};
</script>

<style scoped>
/* Puedes agregar estilos personalizados aquí */
</style>
