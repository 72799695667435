const state = {
  reservation: {
    date: '',
    time: '',
    date_id: '',
    time_id: '',
    transport: '',
    ticket_type: '',
    ticket_quantity: '',
    passengers: [],
  }
};

export default state;
